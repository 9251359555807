<template>
  <div class="p-1">
    <div class="h-20 py-3 flex items-center border-black-200 p-6 mt-5">
      <div class="flex w-full">
        <button
          :class="$colors.primary"
          class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
          @click="RouterBack()"
        >
          <i class="material-icons">chevron_left</i>
        </button>

        <div class="text-xl font-bold text-black-600">Details</div>
      </div>
    </div>

    <div-table
      :config="configList"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :data="content"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      @searchSellers="searchSellers"
      @reset="reset"
      @filtrer="filtrer"
      @refresh="refresh"
    ></div-table>
  </div>
</template>

<script>
import config from "./confighistory";
import _ from 'lodash';

export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      content: [],
      // currentUser: {},
      limit: 10,
      warehouses: [],
      sellers: [],
      types: [],
      filters: {},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
    };
  },

  async mounted() {
    //await this.getUser();
    await this.getWarehouses();
    await this.getStockHistories({ limit: this.limit });
    await this.getUsers();
    this.setDataSelect("user", this.sellers, null);
    await this.searchModelesUserHistory();
  },
  methods: {
    RouterBack: function () {
      this.$router.back();
    },
    setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async searchModelesUserHistory() {
      const res = await this.$server.search("userHistories", {
        distinctPrices: true,
      });
      if (res.content && res.content.results) this.types = res.content.results;
      else this.types = [];
      this.setDataSelect("type", this.types, null);
    },
    async getUsers() {
      const filter = {
        type: { $nin: ["Seller"] },
        // countries:await this.warhouseSelected,
        limit: this.limit,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    searchSellers: _.debounce(async function (search, event) {
      const filter = {
        type: { $nin: ["Seller"] },
        limit: this.limit,
        // countries:await this.warhouseSelected,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
      this.setDataSelect("user", this.sellers, null);
    }, 500),
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        this.warehouses = res.content.results;
      } else this.warehouses = [];
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let whar = o._id.toString();
        return whar == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    async getStockHistories(filters) {
      const res = await this.$server.search("userHistories", filters);

      if (res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for (let i in this.content) {
          this.content[i].date = this.$moment(this.content[i].date).format(
            "yyyy-MM-DD  HH:mm"
          );
          if (
            this.content[i].model &&
            this.content[i].model.action === "update services"
          ) {
            for (let j in this.content[i].oldObject.settings) {
              const wareh = this.getElementInArray(
                this.warehouses,
                this.content[i].oldObject.settings[j].warehouse
              );
              this.content[i].oldObject.settings[j].flag = wareh.flag;
            }
            for (let j in this.content[i].newObject.settings) {
              const wareh = this.getElementInArray(
                this.warehouses,
                this.content[i].newObject.settings[j].warehouse
              );
              this.content[i].newObject.settings[j].flag = wareh.flag;
            }
          }
        }
      } else this.content = [];
    },
    async refresh() {
      this.getStockHistories({ limit: this.limit });
    },
    async reset() {
      this.getStockHistories({ limit: this.limit });
    },
    async filtrer(data) {
      delete this.filters.page;
      delete this.filters.name;
      if (data.date) this.filters.date = data.date;
      else delete this.filters.date;
      this.paginate.currentpage = 1;
      if (data.user) this.filters.user = data.user._id;
      else delete this.filters.user;
      if (data.search) this.filters["model.info"] = data.search;
      else delete this.filters["model.info"];
      if (data.type) this.filters["model.action"] = data.type;
      else delete this.filters["model.action"];
      this.getStockHistories(this.filters);
    },

    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getStockHistories(filters);
    },
  },
};
</script>
