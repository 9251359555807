var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-black-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-bold text-black-600"
  }, [_vm._v("Details")])])]), _c('div-table', {
    attrs: {
      "config": _vm.configList,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "data": _vm.content,
      "loading": _vm.loading,
      "paginate": _vm.paginate
    },
    on: {
      "paginatation": _vm.paginatation,
      "searchSellers": _vm.searchSellers,
      "reset": _vm.reset,
      "filtrer": _vm.filtrer,
      "refresh": _vm.refresh
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }